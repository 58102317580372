import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: true,
  },
  mutations: {
    // 判断是不是手机端
    SET_MOBLIE(state, isMobile) {
      state.isMobile = isMobile;
    },
  },

  actions: {},
  modules: {},
  plugins: [
    persistedState({
      storage: window.sessionStorage,
    }),
  ],
});
