import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    // 一级路由重定向
    path: "/",
    redirect: "/mainIndex",
  },
  {
    path: "/mainIndex",
    name: "mainIndex",
    component: () => import("@/views/mainIndex.vue"),
    // 二级路由
    children: [
      {
        // 二级路由重定向
        path: "/mainIndex",
        redirect: "/mainIndex/shouyeIndex",
      },
      {
        path: "shouyeIndex",
        name: "shouyeIndex",
        component: () => import("@/views/components/shouyeIndex.vue"),
        meta: { title: "酷玩科技-首页" },
      },
      {
        path: "hexinIndex",
        name: "hexinIndex",
        component: () => import("@/views/components/hexinIndex.vue"),
        meta: { title: "酷玩科技-核心优势" },
      },
      {
        path: "jiejueIndex",
        name: "jiejueIndex",
        component: () => import("@/views/components/jiejueIndex.vue"),
        meta: { title: "酷玩科技-解决方案" },
      },
      {
        path: "sucessIndex",
        name: "sucessIndex",
        component: () => import("@/views/components/sucessIndex.vue"),
        meta: { title: "酷玩科技-成功案例" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "active",
});
router.beforeEach((to,from,next) => {
  document.title = to.meta.title
  next()
})

router.beforeEach((to, from, next) => {
  // 兼容chrome
  document.body.scrollTop = 0;
  // 兼容firefox
  document.documentElement.scrollTop = 0;
  // 兼容safari
  window.pageYOffset = 0;
  next();
});

export default router;
