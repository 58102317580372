<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          if (that.screenWidth <= 760) {//当屏幕小于768时，设置Vuex里的数据为true
            that.$store.commit("SET_MOBLIE", false)
          } else {//反之，设置Vuex里的数据为false
            that.$store.commit("SET_MOBLIE", true)
          }
          that.timer = false
        }, 400)
      }
    }
  }

};
</script>
<style lang="less" scoped>
</style>
